<template>
  <div class="app-form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Страна</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.country">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Город</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.city">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Улица</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.street">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Дом</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.house">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Строение</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.building">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Телефон</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.phone">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Долгота</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.lon">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Широта</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.lat">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Время окончания работы</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.date_end" placeholder="hh:mm">
          </div>
        </div>
      </div>
      <!-- <div class="field-body">
        <div class="field">
          <div class="control">
            <b-timepicker
            placeholder="Время окончания работы"
            icon="clock"
            name="date_start"
            v-model="model.date_end"
            :enable-seconds="false"
            :formatAmPm="false"
            :increment-minutes="15"
            :hour-format="'24'">
            </b-timepicker>
          </div>
        </div>
      </div> -->
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Удален (выключен)</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.is_deleted">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Выключен</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.is_deleted">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label">
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { addRestAddress } from '@/api/rest_address'
// import { getRoles } from '@/api/role'
import { state_yesno, EventBus } from '@/utils'

export default {
  name: 'UserFormAdd',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      state_yesno: state_yesno,
      model: {

      }
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  mounted: function(){

    // getRoles(this.$store.state.jwt_user)
    //   .then(response => {
    //     let data = response.data;
    //     data.data.forEach((item) => {
    //       this.roles.push(item)
    //     })
    //   })
    //   .catch(
    //     this.roles = []
    //   )

  },
  methods: {
    doUpdate () {
      // let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          let payload = Object.assign({}, this.model)
          // try{
          //   payload.date_start = this.$moment(this.model.date_start).format('YYYY-MM-DD HH:mm')
          // }
          // catch{
          //    payload.date_start = this.$moment().format('YYYY-MM-DD HH:mm')
          // }
          // try{
          //   payload.date_end = this.$moment(this.model.date_end).format('YYYY-MM-DD HH:mm')
          // }
          // catch{
          //   payload.date_end = this.$moment().format('YYYY-MM-DD HH:mm')
          // }

          addRestAddress(payload, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successAddUser')
              this.$router.push({ name: 'a-rests', params: {} })
            }
          )        

        }
      })
    },
  }
}
</script>

<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style>
</style>